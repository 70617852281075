@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Rubik+Distressed');
@import url('https://fonts.googleapis.com/css?family=Rubik+Glitch');
@import url('https://fonts.googleapis.com/css?family=Ultra');
@import url('https://fonts.googleapis.com/css?family=Special+Elite');
@import url('https://fonts.googleapis.com/css?family=Lora');
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville');

body {
  font-family: 'Libre Baskerville', serif;
}

.special_elite {
  font-family: 'Special Elite', sans-serif;
}

.ultra {
  font-family: 'Ultra', sans-serif;
}

.glitch {
  font-family: 'Rubik Glitch', sans-serif;
}

.bold {
  font-family: 'Rubik Distressed', cursive;
}
